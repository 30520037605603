//import { Controller } from "@hotwired/stimulus-webpack-helpers"

class DropzoneController {
  constructor(element) {
    this.element = element
    this.setupFileInput()
    this.bindEvents()
  }

  setupFileInput() {
    // Creëer een verborgen file input
    this.fileInput = document.createElement('input')
    this.fileInput.type = 'file'
    this.fileInput.accept = 'image/*'
    this.fileInput.style.display = 'none'
    this.element.appendChild(this.fileInput)

    // Voeg click event toe aan de dropzone
    this.element.addEventListener('click', () => this.fileInput.click())
    
    // Handle file selectie via de input
    this.fileInput.addEventListener('change', (e) => {
      if (e.target.files.length > 0) {
        this.uploadFile(e.target.files[0])
      }
    })
  }

  bindEvents() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.element.addEventListener(eventName, this.preventDefaults, false)
    })

    ;['dragenter', 'dragover'].forEach(eventName => {
      this.element.addEventListener(eventName, this.highlight.bind(this), false)
    })

    ;['dragleave', 'drop'].forEach(eventName => {
      this.element.addEventListener(eventName, this.unhighlight.bind(this), false)
    })

    this.element.addEventListener('drop', this.handleDrop.bind(this), false)
  }

  preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  highlight(e) {
    this.preventDefaults(e)
    this.element.classList.add('dragover')
  }

  unhighlight(e) {
    this.preventDefaults(e)
    this.element.classList.remove('dragover')
  }

  handleDrop(e) {
    this.unhighlight(e)
    const files = e.dataTransfer.files
    if (files.length > 0) {
      this.uploadFile(files[0])
    }
  }

  uploadFile(file) {
    if (!file.type.match(/^image\//)) {
      alert('Alleen afbeeldingen zijn toegestaan')
      return
    }

    const formData = new FormData()
    const productId = this.element.closest('form').dataset.productId
    formData.append('product[image]', file)
    
    // Add authenticity token to form data
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    formData.append('authenticity_token', token)

    this.element.classList.add('uploading')
    
    fetch(`/admin/products/${productId}.json`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': token,
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: formData,
      credentials: 'same-origin'
    })
    .then(response => {
      if (response.ok) {
        // Update the preview image without page reload
        response.json().then(data => {
          if (data.image_url) {
            const previewImage = this.element.querySelector('.preview-image img')
            if (previewImage) {
              previewImage.src = data.image_url
            }
            const mainImage = document.querySelector('img[style="width:200px"]')
            if (mainImage) {
              mainImage.src = data.image_url
            }
          }
        })
      } else {
        return response.text().then(text => {
          console.log('Server response:', response.status, text)
          try {
            const data = JSON.parse(text)
            throw new Error(data.error || `Server error: ${response.status} - ${text}`)
          } catch (e) {
            if (response.status === 422) {
              throw new Error('Validatiefout bij het uploaden van de afbeelding. Controleer of het bestand geldig is.')
            } else if (response.status === 404) {
              throw new Error('Product niet gevonden.')
            } else {
              throw new Error(`Er is een fout opgetreden (${response.status}): ${text || 'Onbekende fout'}`)
            }
          }
        })
      }
    })
    .catch(error => {
      console.error('Upload error:', error)
      alert(error.message)
    })
    .finally(() => {
      this.element.classList.remove('uploading')
    })
  }
}

export default DropzoneController 