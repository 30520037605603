//import { Application } from "@hotwired/stimulus"
import DropzoneController from "./dropzone_controller"

document.addEventListener('DOMContentLoaded', () => {
  const dropzones = document.querySelectorAll('.dropzone')
  dropzones.forEach(dropzone => {
    if (dropzone.closest('form[data-product-id]')) {
      new DropzoneController(dropzone)
    }
  })
}) 